import React from "react";
import brand from "../Assets/nymbel-brand.svg";
import logo from "../Assets/nymbel-logo-dark.svg";

function Nymbel() {
  return (
    <>
      <div className="home-page">
        <div className="home-header">
          <div className="home-logo">
            <img src={brand} alt="logo" />
          </div>
          <div className="home-buttons">
            <a href="/app/register">
              <button
                className="home-button"
                disabled={true}
                style={{ backgroundColor: "#33669A" }}
              >
                Register
              </button>
            </a>
            <a href="/app/login">
              <button
                className="home-button"
                disabled={true}
                style={{ backgroundColor: "#29ABE2" }}
              >
                Login
              </button>
            </a>
          </div>
        </div>
        <div className="home-stage">
          <div style={{backgroundColor: "black", opacity: "50%"}}>
            <div className="home-banner" style={{ color: "#29ABE2" }}>
              Coming Spring 2023
            </div>
            <div className="home-message" style={{color: "white" }}>
              Launching with Nymbel Enterprise
            </div>
          </div>
        </div>
        <div className="home-footer">
          <img src={logo} />
          Copyright 2022
        </div>
      </div>
    </>
  );
}

export default Nymbel;
